import React from "react";
import "./App.css";
import Cabinet from "./containers/cabinet/index";
import { Routes, Route } from "react-router-dom";
import Payment from "./containers/payment/index";
import ProgressBar from "./components/progress-bar/progress-bar";
import Finish from "./containers/finish";
import { CartProvider } from "./context";
import Login from "./containers/login";
import StaffPage from "./containers/staff";
import ProductPage from "./containers/staff/product";
import ApiLog from "./containers/staff/apilog";
import TransLog from "./containers/staff/trans";

function App() {
  return (
    <div className="App">
      <CartProvider>
        <Routes>
          <Route
            path="/:id"
            element={
              <>
                <ProgressBar step={1} />
                <Cabinet />
              </>
            }
          />
          <Route
            path="/payment"
            element={
              <>
                <ProgressBar step={2} />
                <Payment />
              </>
            }
          />
          <Route
            path="/finish"
            element={
              <>
                <ProgressBar step={3} />
                <Finish />
              </>
            }
          />
          <Route path="/stafflogin" element={<Login />} />
          <Route path="/staff" element={<StaffPage />} />
          <Route path='/product' element={<ProductPage/>}/>
          <Route path='/log' element={<ApiLog/>}/>
          <Route path="/transLog" element={<TransLog/>}/>
          <Route path="/403" element={<p>Forbident</p>} />
        </Routes>
      </CartProvider>
    </div>
  );
}

export default App;
