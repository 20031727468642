import React, { useEffect, useState, useContext } from "react";
import AppBar from "../../components/progress-bar/appbar";
import {useNavigate,useLocation} from 'react-router-dom';
import { AddProduct, GetAllProduct,Product, PullOutProduct, UploadProductImage} from  "../../services/api";
import { CListGroup, CListGroupItem, CRow ,CCol, CButton} from "@coreui/react";
import ProductCard from "../../components/product/product-card";
import EditProductModal from "../../components/product/product-edit";

const ProductPage:React.FC = ()=>{
    const nav = useNavigate();
    const [product,setProduct] = useState<Product[]>([]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentProduct, setCurrentProduct] = useState<Product>({price:20,productId:"",imageURL:"",productDescription:"",productName:""});
  
    const handleModalClose = () => {
      setIsModalOpen(false);
    };
    const handleAddProduct = async (updatedProduct: Product,image:File|null) =>  {
        console.log(updatedProduct)
        const createdPrduct = await AddProduct(updatedProduct);
        if(image){
          await UploadProductImage(image,createdPrduct.productId)
        }
        setCurrentProduct(createdPrduct);
        setIsModalOpen(false);
        window.location.reload();
      };
    useEffect(() => {
        if (sessionStorage.getItem('user')===null) {
          nav("/403");
        }
        document.title = 'Product management';
        GetAllProduct().then(products=>setProduct(products))
      }, []);
    return <>
    <AppBar/>
    <CRow  className="justify-content-end">
        <CCol md={3} className="my-2"><CButton color="primary" onClick={()=>{setIsModalOpen(true)}} >เพิ่มสินค้า</CButton></CCol>
    </CRow>
      <CListGroup>
        {product.map((x)=>(<CListGroupItem key={x.productId}><ProductCard product={x} key={x.productId}/></CListGroupItem>))}
      </CListGroup>
      <EditProductModal
 product={currentProduct}
 visible={isModalOpen}
 onClose={handleModalClose}
 onSave={handleAddProduct}
/>
    </>
}
export default ProductPage;