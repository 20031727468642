import { CButton, CContainer, CForm, CFormInput, CFormTextarea } from "@coreui/react";
import React, { useEffect, useState } from "react";
import { AddComment } from "../../services/api";
import { useLocation, useNavigate } from "react-router-dom";

const Finish = () => {
  const [comment,setComment] = useState("")
  const nav = useNavigate();
  const { state } = useLocation();
  useEffect(()=>{
    if (!(state && state.transId)) {
      nav("/403");
    }
  },[])
  return <div>
<CContainer>
  <CForm>
    <CFormTextarea label="ขอบคุณที่ใช้บริการ โปรดแสดงความรู้สึกในการใช้บริการครั้งนี้" rows={4} onChange={(e)=>{
      setComment(e.target.value)
    }}/>
    <CButton color="success" className="text-white my-3" onClick={()=>{
      AddComment(comment,state.transId);
      nav("/f2f72373-69c8-47e0-8a68-aaf4cfd27648")
      }}>ยืนยัน</CButton>
  </CForm>
</CContainer>
  </div>;
};
export default Finish;
