import { CContainer, CTable } from "@coreui/react";
import React, { useEffect, useState, useContext } from "react";
import AppBar from "../../components/progress-bar/appbar";
import { Log, GetLog } from "../../services/api";
import { useNavigate } from "react-router-dom";
const ApiLog: React.FC = () => {
  const columns = [
    { key: "log_date", label: "เวลา", _props: { scope: "col" } },
    { key: "user_name", label: "ผู้ใช้งาน", _props: { scope: "col" } },
    { key: "method", label: "คำสั่ง", _props: { scope: "col" } },
  ];
  const [log, setLogs] = useState<Log[]>([]);
  const nav = useNavigate();
  useEffect(() => {
    if (sessionStorage.getItem("user") === null) {
      nav("/staffLogin");
    }
    GetLog().then((val) => setLogs(val));
  }, []);
  return (
    <>
      <AppBar />
      <CContainer>
        <CTable
          columns={columns}
          items={log.map((item) => {
            return {
              log_date: item.logDate,
              user_name: item.user,
              method: item.method,
              _cellProps: { class: { scope: "row" } },
            };
          })}
        />
      </CContainer>
    </>
  );
};
export default ApiLog;
