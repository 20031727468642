import React, { useEffect, useState, useRef, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Confirm, SaleResponse } from "../../services/api";
import { CRow, CButton, CForm, CContainer } from "@coreui/react";
import { toJpeg } from "html-to-image/";

interface PaymentProps {}
const Payment: React.FC<PaymentProps> = () => {
  const { state } = useLocation();
  const nav = useNavigate();
  const [errMsg, setErrMsg] = useState("");
  useEffect(() => {
    if (!(state && state.transId)) {
      nav("/403");
    }
  }, []);
  if (!(state && state.transId)) {
    return <div></div>;
  }
  const sale: SaleResponse = state;
  const ref = useRef<HTMLDivElement>(null);
  const onButtonClick = useCallback(() => {
    if (ref.current === null) {
      return;
    }
    toJpeg(ref.current)
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "qrpayment.jpg";
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ref]);
  return (
    <div ref={ref}>
      <CRow className="justify-content-center">
        <CContainer>
          <img  className="mx-5"
            id="qr-code"
            src={`data:image/svg+xml;utf8,${encodeURIComponent(sale.qrCodeText ?? "<svg></svg")}`}
            onClick={onButtonClick}
          />
          <p>กด QR Code เพื่อบันทึกรูปภาพ</p>

          <p className="p-2 border rounded-3 mx-2">{`รวมราคา ${sale.transPrice} บาท`}</p>
        </CContainer>
        <p className="text-danger">{errMsg}</p>
        <CForm>
          <CButton
            color="success"
            className="text-white"
            onClick={async () => {
              try {
                if (!sale.paymentReference) {
                  throw "paymentReference undefind";
                }
                await Confirm(sale.paymentReference);
                nav("/finish",{state:state});
              } catch (err) {
                setErrMsg("ไม่พบการชำระเงิน กรุณาติดต่อ ...")
                console.log(err);
              }
            }}
          >
            ยืนยันการชำระเงิน
          </CButton>
        </CForm>
      </CRow>
    </div>
  );
};
export default Payment;
