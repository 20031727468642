import { Slot } from "./api";

export const isUUID = ( uuid:string ) => {
    let s:string = "" + uuid;

    const r = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
    if (r === null) {
      return false;
    }
    return true;
}
export const getSlotNumber = (slot:Slot) => {
  return (slot.rowId*6)+slot.columnId+1
}
