// src/Login.tsx
import React, { useState, ChangeEvent, FormEvent } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { CButton, CCard, CCardBody, CCol, CContainer, CForm, CFormInput, CInputGroup, CInputGroupText, CRow } from '@coreui/react';
import { Login as StaffLogin,Staff} from  "../../services/api";

const Login: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errMsg,setErrMsg] = useState(""); 
  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleLogin = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try{
      const res:Staff = await StaffLogin({userName:email,password:password})
      sessionStorage.setItem('user',JSON.stringify(res))
      sessionStorage.setItem('token',res.jwt);
      nav("/staff",{state:res});
    }
    catch {
      setErrMsg("Please try again.")
    }
  };
  const nav = useNavigate();
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCard className="p-4">
              <CCardBody>
                <CForm onSubmit={handleLogin}>
                  <h1>Login</h1>
                  <p className="text-medium-emphasis">Sign In to your account</p>
                  <CInputGroup className="mb-3">
                    <CInputGroupText>@</CInputGroupText>
                    <CFormInput
                      placeholder="User name"
                      value={email}
                      onChange={handleEmailChange}
                    />
                  </CInputGroup>
                  <CInputGroup className="mb-4">
                    <CInputGroupText>🔒</CInputGroupText>
                    <CFormInput
                      type="password"
                      placeholder="Password"
                      autoComplete="current-password"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                  </CInputGroup>
                  <CRow>
                    <CCol xs="6">
                      <CButton type="submit" color="primary" className="px-4">
                        Login
                      </CButton>
                    </CCol>
                  </CRow>
                  <CRow>

                      <p className='text-danger'>{errMsg}</p>
                  
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Login;
