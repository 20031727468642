import React, { useContext, useEffect, useState } from "react";
import "./index.css";
import {
  CContainer,
  CRow,
  CCol,
  CButton,
  CForm,
} from "@coreui/react";
import { useNavigate, useParams } from "react-router-dom";
import ItemCard from "../../components/item-card/item-card";
import { GetCabinet, CabinetSlot, SaleRequest, Sale } from "../../services/api";
import { CartContext } from "../../context";
import { isUUID } from "../../services/utils";
interface DrawRowProps {
  rows: CabinetSlot[][];
}

const DrawRow: React.FC<DrawRowProps> = ({ rows }) => {
  return (
    <CContainer>
      {rows.map((row, index) => {
        if (row === undefined) {
          return <div></div>;
        }
        return (
          <CRow key={`cabinet_row_${index}`} className='g-1' >
            {row.map((item) => (
              <CCol key={item.slotId} xs={2}>
                <ItemCard data={item} isAdmin={false} />
              </CCol>
            ))}
          </CRow>
        );
      })}
    </CContainer>
  );
};


const Cabinet = () => {
  function groupListByValue(list: CabinetSlot[]) {
    return Object.values(
      list.reduce((groups: { [key: string]: CabinetSlot[] }, item) => {
        const groupKey = item.rowId;
        if (!groups[groupKey]) {
          groups[groupKey] = [];
        }
        groups[groupKey].push(item);
        return groups;
      }, {}),
    ).sort((a, b) => a[0].rowId - b[0].rowId);
  }
  const { cart, setCart } = useContext(CartContext);
  const [errMsg,setErrMsg] = useState(""); 
  const { id } = useParams<string>();
  useEffect(() => {
    const fetch = async (id:string) => {
      document.title = 'Em';
      const cabinet = await GetCabinet(id);
      const row = groupListByValue(cabinet.slots);
      setData(row);
    };
    if (id && isUUID(id)) {
      fetch(id);
    }
  }, [id]);
  const [data, setData] = useState<CabinetSlot[][]>([[]]);
  const nav = useNavigate();
  if (!(id && isUUID(id))) {
    return <h1>หมายเลขตู้สินค้าไม่ถูกต้อง</h1>;
  }
  return (
    <div>
      <DrawRow rows={data} />
        <CContainer>
        <p className="p-2 border rounded-3 single-line-text">{`รวมราคา ${cart.map((x) => x.filledProduct.price).reduce((acc, cur) => acc + cur, 0)} บาท`}</p>
        </CContainer>
        <p className="text-danger">{errMsg}</p>
        <CButton
          color="success"
          className="text-white single-line-text"
          onClick={async () =>  {
            if (cart && cart.length > 0) {
              const saleRequest: SaleRequest = {
                cabinetId: id,
                productQuantityMap: {},
                slotIds: cart.map((x) => x.slotId),
              };
              try{
                const res = await Sale(saleRequest)
                nav("/payment",{state:res});
              }
              catch(ex){
                setErrMsg("ไม่สามารถทำรายการได้ โปรดลองใหม่")
                console.log(ex)
              }
            }
            
          }}
        >
          ยืนยันรายการสินค้า
        </CButton>
    </div>
  );
};

export default Cabinet;
