import {
  CButton,
  CCard,
  CCardBody,
  CCardImage,
  CCardText,
  CCardTitle,
  CCol,
  CRow,
} from "@coreui/react";
import { Product,EditProduct,UploadProductImage } from "../../services/api";
import React, { useState } from "react";
import EditProductModal from "./product-edit";
interface ItemCardProps {
  product: Product;
}
const ProductCard: React.FC<ItemCardProps> = ({ product }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentProduct, setCurrentProduct] = useState<Product>(product);
  const handleEditClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleSaveProduct = async (updatedProduct: Product,image:File|null) =>  {
    await EditProduct(updatedProduct);
    if(image){
      await UploadProductImage(image,updatedProduct.productId)
    }
    setCurrentProduct(updatedProduct);
    setIsModalOpen(false);
    window.location.reload();
  };

  return (<>
    <CCard className="mb-3">
  <CRow className="g-0">
    <CCol md={3}>
      <CCardImage src={product.imageURL} />
    </CCol>
    <CCol md={7}>
      <CCardBody>
        <CCardTitle>{product.productName}</CCardTitle>
        <CCardText>
          {product.productDescription}
        </CCardText>
        <CCardText>
          ราคา {product.price} บาท
        </CCardText>
      </CCardBody>
    </CCol>
    <CCol md={1}  className="align-self-center">
      <CButton color="warning" className="mb-2" onClick={handleEditClick}>Edit</CButton>
    </CCol>
  </CRow>
</CCard>
 <EditProductModal
 product={currentProduct}
 visible={isModalOpen}
 onClose={handleModalClose}
 onSave={handleSaveProduct}
/></>
  );
};

export default ProductCard;
