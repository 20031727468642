import React, { ChangeEvent, useState } from 'react';
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CButton,
  CForm,
  CFormInput,
  CFormTextarea,
} from '@coreui/react';
import { Product } from '../../services/api';
interface EditProductModalProps {
    product: Product;
    visible: boolean;
    onClose: () => void;
    onSave: (updatedProduct: Product,image:File|null) => void;
  }

  const EditProductModal: React.FC<EditProductModalProps> = ({ product, visible, onClose, onSave }) => {
    const [editedProduct, setEditedProduct] = useState<Product>(product);
    const [imageFile, setImageFile] = useState<File | null>(null);
    const [previewImgUrl,setPreviewImgUrl] = useState('');
  
    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      setEditedProduct({ ...editedProduct, [name]: value });
    };
  
    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        setImageFile(e.target.files[0]);
        setPreviewImgUrl(URL.createObjectURL(e.target.files[0]));
      }
    };
  
    const handleSave = async () => {
      let updatedProduct = { ...editedProduct };
      onSave(updatedProduct,imageFile);
      onClose();
    };
    return (
        <CModal visible={visible} onClose={onClose}>
          <CModalHeader closeButton>
            <CModalTitle>Edit Product</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <CForm>
              <CFormInput
                id="productName"
                name="productName"
                label="Product Name"
                value={editedProduct.productName}
                onChange={handleChange}
              />
              <CFormTextarea
                id="productDescription"
                name="productDescription"
                label="Product Description"
                value={editedProduct.productDescription}
                onChange={handleChange}
              />
              <CFormInput
                id="price"
                name="price"
                label="Price"
                type="number"
                value={editedProduct.price}
                onChange={handleChange}
              />
              <CFormInput
                id="imageUpload"
                name="imageUpload"
                label="Upload Image"
                type="file"
                accept="image/*"
                onChange={handleFileChange}
              />
              {editedProduct.imageURL && (
                <>
                  <label>Preview</label>
                  <img
                    src={previewImgUrl==''?editedProduct.imageURL:previewImgUrl}
                    alt="Product"
                    style={{ maxWidth: '100%', marginTop: '10px' }}
                  />
                </>
              )}
            </CForm>
          </CModalBody>
          <CModalFooter>
            <CButton color="secondary" onClick={onClose}>
              Cancel
            </CButton>
            <CButton color="primary" onClick={handleSave}>
              Save Changes
            </CButton>
          </CModalFooter>
        </CModal>
      );
    };
    
    export default EditProductModal;