import axios, { AxiosRequestConfig } from "axios";

const apiUrl = process.env.REACT_APP_API_URL;
const apiToken = process.env.REACT_APP_API_KEY;
// const apiToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.cvG0RITr4PBO4oRpcPNyl4BQ-dRjYyXUp62rwqbfLcg';

const config:AxiosRequestConfig = {
  headers: { Authorization: `Bearer ${apiToken}` },
  // baseURL: 'https://api.worachet-lab.work'
   baseURL: apiUrl
};
function getAxiosConfig(): AxiosRequestConfig {
  // Retrieve the token from sessionStorage
  const apiToken = sessionStorage.getItem('token');

  // Return the Axios config object
  return {
    headers: { Authorization: `Bearer ${apiToken}` },
    baseURL: apiUrl
  };
}

export interface CabinetSlot {
  slotId: string;
  filledProductId: string;
  rowId: number;
  columnId: number;
  hardwarePin: number;
  unlockTrigger: number;
  status: number;
  physicalLocked:boolean;
  updateDate: string;
  filledProduct: Product;
}
export interface SaleRequest {
  cabinetId: string,
  slotIds: string[],
  productQuantityMap: {}
}
export interface SaleResponse {
  transId?: string;
  cabinetId?: string;
  slotIds?: string[];
  productQuantityMap?: { [key: string]: number };
  transPrice: number;
  paymentReference?: string;
  qrCodeLink?: string;
  qrCodeText?: string;
  transDate?: Date;
  transCancelDate?: Date;
  transPaymentDate?: Date;
  transRefundDate?: Date;
  slotUnlockDate?: Date;
  comment?:string;
  slots:Slot[];
}
export interface Slot{
  transFilledProduct?: Product,
  rowId: number,
  columnId: number
}
export interface FillSlotRequest {
  cabinetId: string,
  SlotProductMap: {[key: string]:string}
}
export interface LoginRequest {
  userName: string,
  password: string
}
export interface Staff {
  userId:string,
  userName:string,
  jwt:string
}
export interface Product {
  productId: string;
  productName: string;
  productDescription: string;
  price: number;
  imageURL: string;
};
export interface Cabinet {
  cabinetId: string;
  cabinetName:string;
  locationName:string;
  lastHeartbeatDate:Date;
}
export interface Log {
  logDate:Date,
  method:string,
  user:string
}
export const Sale = async (model: SaleRequest) => {
  const response = await axios.post<SaleResponse>(
    `/api/Sale/Sale`,
    model,
    config,
  );
  if(response.status !== 200){
    throw `not success with http status ${response.status} data ${response.data}`
  }
  return response.data;
};
export const GetCabinet = async (id: string) => {
  const response = await axios.post(
    `/api/Cabinet/GetCabinet?id=${id}`,
    {},
    config,
  );
  return response.data;
};
export const GetAllCabinet = async () => {
  const response = await axios.post<Cabinet[]>(`/api/Cabinet/GetAllCabinet`,
    {},
    config);
   return  response.data; 
}
export const UploadProductImage = async (file: File,id: string) => {
  const formData = new FormData();
  formData.append("file",file);
  formData.append("id",id);
  await axios.post(
    `/api/Product/UploadProductImage`,
    formData,
    getAxiosConfig(),
  );
}
export const FillSlot = async (fillRequest : FillSlotRequest) => {
  console.log( JSON.stringify(fillRequest))
  await axios.post(
    `/api/Maintenance/FillSlot`,
    fillRequest,
    getAxiosConfig(),
  );
}
export const OpenToFillSlot = async (fillRequest : FillSlotRequest) => {
  await axios.post(
    `/api/Maintenance/OpenToRefill`,
    fillRequest,
    getAxiosConfig(),
  );
}
export const PullOutProduct = async (fillRequest : FillSlotRequest) => {
  await axios.post(
    `/api/Maintenance/PullOutProduct`,
    fillRequest,
    getAxiosConfig(),
  );
}
export const Confirm = async (chargeId: string) => {
  await axios.post(
    `/api/Payment/Confirm?chargeId=${chargeId}`,
    {},
    config,
  );
};

export const Login = async (loginRequest: LoginRequest) => {
  const response = await axios.post<Staff>(
    `/api/Maintenance/StaffLogin`,
    loginRequest,
    config,
  );
  return response.data;
};
export const GetAllProduct = async () => {
  const response = await axios.post<Product[]>(
    `/api/Product/GetAllProduct`,
    {},
    config,
  );
  return response.data;
}
export const AddProduct = async (product:Product) => {
  const response = await axios.post<Product>(
    `/api/Product/AddProduct`,
    product,
    getAxiosConfig(),
  );
  return response.data;
}
export const EditProduct = async (product:Product) => {
  const response = await axios.post<Product>(
    `/api/Product/UpdateProduct`,
    product,
    getAxiosConfig(),
  );
  return response.data;
}
export const AddComment = async (comment:string,transId:string) => {
  await axios.post(
    `/api/Sale/Comment`,
    {text:comment,transId:transId},
    config,
  );
}
export const GetLog = async () => {
  const response =  await axios.get<Log[]>('/api/sale/getapilog?page=1&size=100',config);
  return response.data;
}
export const GetTrans = async () => {
  const response =  await axios.get<SaleResponse[]>('/api/sale/getsaletrans?page=1&size=100',config);
  return response.data;
}