import React, { createContext,useContext ,useState } from 'react';
import {CabinetSlot } from '../services/api';

interface ContextType {
    cart:CabinetSlot[],
    setCart: (cartToSet: CabinetSlot[]) => void;
}
export const CartContext = createContext<ContextType>({cart:[],setCart:(cartToSet:CabinetSlot[])=>{}});

// Custom hook to consume the context
export const useCart = () => {
    const context = useContext(CartContext);
    if (!context) {
      throw new Error('useCart must be used within a CartProvider');
    }
    return context;
  };

export const CartProvider: React.FC<{ children: React.ReactNode }>= ({children}) => {
    const [cart,setCart] = useState<CabinetSlot[]>([])
    return (
        <CartContext.Provider value={{cart,setCart}}>
            {children}
        </CartContext.Provider>
    )
}
