import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CNavbar,
  CContainer,
  CNavbarBrand,
  CNavbarToggler,
  CCollapse,
  CNavbarNav,
  CNavItem,
  CNavLink,
  CButton,
  CDropdown,
  CDropdownToggle,
  CDropdownItem,
  CDropdownMenu,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilMenu, cilBell, cilEnvelopeOpen, cilUser } from "@coreui/icons";
import { Cabinet, GetAllCabinet } from "../../services/api";

const AppBar: React.FC = () => {
  const nav = useNavigate();
  const [visible, setVisible] = useState(false);
  const [cabinet, setCabinet] = useState<Cabinet[]>([]);
  useEffect(() => {
    GetAllCabinet().then((x) => {
      setCabinet(x);
      console.dir(x);
    });
  }, []);
  return (
    <CNavbar expand="sm" colorScheme="dark" className="bg-dark">
      <CContainer fluid>
        <CNavbarBrand href="/staff">Vending Staff</CNavbarBrand>
        <CNavbarToggler
          aria-label="Toggle navigation"
          onClick={() => setVisible(!visible)}
        >
          <CIcon icon={cilMenu} />
        </CNavbarToggler>
        <CCollapse className="navbar-collapse" visible={visible}>
          <CNavbarNav className="me-auto">
          <CDropdown>
              <CDropdownToggle color="secondary">
                เลือกตู้สินค้า
              </CDropdownToggle>
              <CDropdownMenu>
                {cabinet.map((x) => {
                  return (
                    <CDropdownItem
                      key={x.cabinetId}
                      href={"?id=" + x.cabinetId}
                    >
                      {x.cabinetName}@{x.locationName}
                    </CDropdownItem>
                  );
                })}
              </CDropdownMenu>
            </CDropdown>
            <CNavItem>
              <CNavLink href="/product">จัดการสินค้า</CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink href="/translog">ประวัติการซื้อ</CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink href="/log">ประวัติการจัดการ</CNavLink>
            </CNavItem>
           
          </CNavbarNav>
          <CNavbarNav>
            <CNavItem>
              <CButton color="light" onClick={()=>{
                      sessionStorage.removeItem('user');
                      sessionStorage.removeItem('token');
                      nav('/stafflogin')
              }}>
                Log out
                <CIcon icon={cilUser} />
              </CButton>
            </CNavItem>
          </CNavbarNav>
        </CCollapse>
      </CContainer>
    </CNavbar>
  );
};

export default AppBar;
