import React, { SVGProps, useContext } from "react";
import "./item-card.css";
import { ReactComponent as Icon } from "../../assets/empty-box-icon.svg";
import {
  CBadge,
  CCard,
  CCardBody,
  CCardFooter,
  CCardImage,
} from "@coreui/react";
import { CabinetSlot } from "../../services/api";
import { useCart } from "../../context";


interface ItemCardProps {
  data: CabinetSlot,
  isAdmin: Boolean
}
const ItemCard: React.FC<ItemCardProps> = ({ data,isAdmin }) => {
  const { cart, setCart } = useCart();
  if (data.filledProduct && data.status===1 ) {
    const product = data.filledProduct;
    return (
      <CCard
        className={`mb-2 hover border-${data.physicalLocked?'light':'warning'}`}  
        onClick={() => {
          if (cart.some((item) => item.slotId === data.slotId)) {
            const updatedCart = cart.filter(
              (item) => item.slotId !== data.slotId,
            );
            setCart(updatedCart);
          } else {
            setCart([...cart, data]);
          }
        }}
      >
        <div
          className={
            cart.some((item) => item.slotId === data.slotId)
              ? "visible"
              : "invisible"
          }
        >
          <CBadge
            className="border border-light p-2"
            color="primary"
            position="top-end"
            shape="rounded-circle"
          >
            &#10003;
            <span className="visually-hidden">New alerts</span>
          </CBadge>
        </div>
        <CCardImage src={product.imageURL} />
        {/* <CCardBody className="card-text single-line-text">{product.productName}</CCardBody> */}
        <CCardFooter className="card-text single-line-text">{product.price}฿</CCardFooter>
      </CCard>
    );
  }
  return (
    <CCard className={`mb-2 hover  border-${data.physicalLocked?'light':'warning'}`}   onClick={() => {
      if(isAdmin){
        if (cart.some((item) => item.slotId === data.slotId)) {
          const updatedCart = cart.filter(
            (item) => item.slotId !== data.slotId,
          );
          setCart(updatedCart);
        } else {
          setCart([...cart, data]);
        }
      }
    }}>
      <Icon fill="#fcba03" />
      <CCardFooter className="card-text single-line-text">หมด</CCardFooter>
      <div
          className={
            cart.some((item) => item.slotId === data.slotId)
              ? "visible"
              : "invisible"
          }
        >
          <CBadge
            className="border border-light p-2"
            color="primary"
            position="top-end"
            shape="rounded-circle"
          >
            &#10003;
            <span className="visually-hidden">New alerts</span>
          </CBadge>
        </div>
    </CCard>
  );
};

export default ItemCard;
