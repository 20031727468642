import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  CabinetSlot,
  FillSlotRequest,
  GetCabinet,
  FillSlot,
  OpenToFillSlot,
  GetAllProduct,
  Product,
  PullOutProduct,
} from "../../services/api";
import { CButton, CCol, CContainer, CFormSelect, CRow } from "@coreui/react";
import { CartContext } from "../../context";
import ItemCard from "../../components/item-card/item-card";
import AppBar from "../../components/progress-bar/appbar";
const StaffPage: React.FC = () => {
  const nav = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState<CabinetSlot[][]>([[]]);
  const [product, setProduct] = useState<any[]>([]);
  const { cart, setCart } = useContext(CartContext);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [cabinetId, setCabinetId] = useState("");
  const fetch = async (id: string) => {
    const cabinet = await GetCabinet(id);
    const row = groupListByValue(cabinet.slots);
    setData(row);
  };
  
  useEffect(() => {
    if (sessionStorage.getItem("user") === null) {
      nav("/staffLogin");
    }
    document.title = "Staff Mode";
    const cabId = searchParams.get("id")??''
    setCabinetId(cabId);
    if(cabId !== ''){
      fetch(cabId);
      GetAllProduct().then((products) => {
        setProduct(
          products.map((x) => ({ label: x.productName, value: x.productId })),
        );
        if (products !== null && products.length > 0) {
          setSelectedProduct(products[0].productId);
        }
      });
    }

  }, []);

  function groupListByValue(list: CabinetSlot[]) {
    return Object.values(
      list.reduce((groups: { [key: string]: CabinetSlot[] }, item) => {
        const groupKey = item.rowId;
        if (!groups[groupKey]) {
          groups[groupKey] = [];
        }
        groups[groupKey].push(item);
        return groups;
      }, {}),
    ).sort((a, b) => a[0].rowId - b[0].rowId);
  }
  function mapStringsToSlotProductMap(strings: string[]): {
    [key: string]: string;
  } {
    const slotProductMap: { [key: string]: string } = {};

    strings.forEach((str, index) => {
      slotProductMap[str] = selectedProduct; // Assign a unique key for each string
    });

    return slotProductMap;
  }
if(cabinetId === ''){
  return <AppBar />
}
  return (
    <div>
      <AppBar />
      <DrawRow rows={data} />
      <CContainer>
        <CRow>
          <CCol>เลือกสินค้าที่ต้องการเติม</CCol>
          <CCol>
            <CFormSelect
              className="my-1"
              options={product}
              onChange={(event) => {
                setSelectedProduct(event.target.value);
              }}
            />
          </CCol>
        </CRow>
        <CButton
          color="warning"
          className="text-white single-line-text mx-1"
          onClick={async () => {
            if (cart && cart.length > 0) {
              const fillRequest: FillSlotRequest = {
                cabinetId: cabinetId,
                SlotProductMap: mapStringsToSlotProductMap(
                  cart.map((x) => x.slotId),
                ),
              };
              await OpenToFillSlot(fillRequest);
              await fetch(cabinetId);
              setCart([]);
            }
          }}
        >
          เปิดประตู
        </CButton>
        <CButton
          color="success"
          className="text-white single-line-text mx-1"
          onClick={async () => {
            if (cart && cart.length > 0) {
              const fillRequest: FillSlotRequest = {
                cabinetId: cabinetId,
                SlotProductMap: mapStringsToSlotProductMap(
                  cart.map((x) => x.slotId),
                ),
              };
              await FillSlot(fillRequest);
              await fetch(cabinetId);
              setCart([]);
            }
          }}
        >
          เติมสินค้า
        </CButton>
        <CButton
          color="success"
          className="text-white single-line-text mx-1"
          onClick={async () => {
            if (cart && cart.length > 0) {
              const fillRequest: FillSlotRequest = {
                cabinetId: cabinetId,
                SlotProductMap: mapStringsToSlotProductMap(
                  cart.map((x) => x.slotId),
                ),
              };
              await PullOutProduct(fillRequest);
              await fetch(cabinetId);
              setCart([]);
            }
          }}
        >
          นำสินค้าออก
        </CButton>
      </CContainer>
    </div>
  );
};
interface DrawRowProps {
  rows: CabinetSlot[][];
}
const DrawRow: React.FC<DrawRowProps> = ({ rows }) => {
  return (
    <CContainer>
      {rows.map((row, index) => {
        if (row === undefined) {
          return <div></div>;
        }
        return (
          <CRow key={`cabinet_row_${index}`} className="g-1">
            {row.map((item) => (
              <CCol key={item.slotId} xs={2}>
                <ItemCard data={item} isAdmin={true} />
              </CCol>
            ))}
          </CRow>
        );
      })}
    </CContainer>
  );
};

export default StaffPage;
