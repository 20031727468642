import { CContainer, CTable } from "@coreui/react";
import React, { useEffect, useState, useContext } from "react";
import AppBar from "../../components/progress-bar/appbar";
import { Log, GetTrans, SaleResponse, Slot } from "../../services/api";
import { getSlotNumber } from "../../services/utils";
import { useNavigate } from "react-router-dom";

const TransLog: React.FC = () => {
  const columns = [
    { key: "transDate", label: "เวลา", _props: { scope: "col" } },
    { key: "transId", label: "เลขที่อ้างอิง", _props: { scope: "col" } },
    { key: "transPrice", label: "ยอดเงิน", _props: { scope: "col" } },
    { key: "comment", label: "ความคิดเห็น", _props: { scope: "col" } },
    { key: "slot", label: "รายละเอียด", _props: { scope: "col" } },
    { key: "payment", label: "รหัสชำระ", _props: { scope: "col" } },
    { key: "paymentDate", label: "วันที่ชำระ", _props: { scope: "col" } },
  ];
  function renderSlot(slots: Slot[]) {
    let slotMsg: string = "";
    if (slots === null) {
      return "";
    }
    slots.forEach((element) => {
      slotMsg = slotMsg + `- ช่องที่ ${getSlotNumber(element)}`;
      if (element.transFilledProduct !== null) {
        slotMsg =
          slotMsg +
          ` สินค้า ${element.transFilledProduct?.productName} ราคา ${element.transFilledProduct?.price}`;
      }
    });
    return slotMsg;
  }
  const [log, setLogs] = useState<SaleResponse[]>([]);
  const nav = useNavigate();
  useEffect(() => {
    if (sessionStorage.getItem("user") === null) {
        nav("/staffLogin");
      }
    GetTrans().then((val) => setLogs(val));
  }, []);
  return (
    <>
      <AppBar />
      <CContainer>
        <CTable
          columns={columns}
          items={log.map((item) => {
            return {
              transDate: item.transDate,
              transId: item.transId,
              transPrice: item.transPrice,
              comment: item.comment,
              payment: item.paymentReference,
              paymentDate: item.transPaymentDate,
              slot: renderSlot(item.slots),
              _cellProps: { class: { scope: "row" } },
              _props: { color: item.transPaymentDate===null?"warning":"success" },
            };
          })}
        />
      </CContainer>
    </>
  );
};
export default TransLog;
