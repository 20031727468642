import { CCol, CContainer, CRow } from "@coreui/react";
import React from "react";
interface ProgressBarProps {
  step: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ step }) => {
  return (
    <CContainer>
      <CRow className="mb-4 text-white">
        <CCol
          className={
            "py-3 bg-gradient border border-3" +
            (step === 1 ? " bg-primary " : " bg-secondary")
          }
        >
          เลือกสินค้า
        </CCol>
        <CCol
          className={
            "py-3 bg-gradient border border-3" +
            (step === 2 ? " bg-warning " : " bg-secondary")
          }
        >
          ชำระเงิน
        </CCol>
        <CCol
          className={
            "py-3 bg-gradient border border-3" +
            (step === 3 ? " bg-success " : " bg-secondary")
          }
        >
          รับสินค้า
        </CCol>
      </CRow>
    </CContainer>
  );
};
export default ProgressBar;
